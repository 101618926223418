.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  background: #f0f2f5;
}

.lang {
  width: 100%;
  height: 40px;
  line-height: 44px;
  text-align: right;
}

.lang .ant-dropdown-trigger {
  margin-right: 24px;
}

.content {
  flex: 1;
  padding: 32px 0;
  margin-top: 10%;
}

@media (min-width: 770px) {
  .container {
    background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
  }

  .content {
    padding: 32px 0 24px;
  }
}

.top {
  text-align: center;
}

.header a {
  text-decoration: none;
}

.loginLogo {
  height: 60px;
  margin-right: 16px;
  vertical-align: top;
}

.title {
  position: relative;
  top: 2px;
  color: black;
  font-weight: 600;
  font-size: 33px;
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.desc {
  margin-top: 0;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.main {
  width: 368px;
  margin: 0 auto;
  padding: 35px;
  border-radius: 8px;
  border: 1px solid #dddfe1;
  background-color: #ffffff;
  box-shadow: 0px 2px 5px 0px #0000001f;
}

@media (max-width: 770px) {
  .main {
    width: 95%;
    max-width: 328px;
  }
}

.main .ant-prefix-tabs-nav-list {
  margin: auto;
  font-size: 16px;
}

.main .icon {
  margin-left: 16px;
  color: rgba(0, 0, 0, 0.2);
  font-size: 24px;
  vertical-align: middle;
  cursor: pointer;
  transition: color 0.3s;
}

.main .icon:hover {
  color: yellow;
}

.main .other {
  margin-top: 24px;
  line-height: 22px;
  text-align: left;
}

.main .other .register {
  float: right;
}

.main .prefixIcon {
  color: #1890ff;
  font-size: 14px;
}
